import { Box, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import animationIllustration from '../../assets/img/animation-illus.svg';
import allianzIcon from '../../assets/img/customers/allianz.png';
import edfIcon from '../../assets/img/customers/edf.png';
import franceTravailIcon from '../../assets/img/customers/france-travail.png';
import onetIcon from '../../assets/img/customers/onet.png';
import sncfIcon from '../../assets/img/customers/sncf.png';
import fmdIllustration from '../../assets/img/fmd-illus.svg';
import incitationIllustration from '../../assets/img/incitation-illus.svg';
import { Card } from '../../components';

export const reasons = ['incitation', 'animation', 'fmd'] as const;

type TReason = (typeof reasons)[number];

export const reasonsMap: {
  [key in TReason]: {
    descriptionKey: string;
    illustration: string;
    titleKey: string;
  };
} = {
  fmd: {
    titleKey: 'companies.pages.new-company.appointment_booking.encourage_reasons.fmd_title',
    descriptionKey:
      'companies.pages.new-company.appointment_booking.encourage_reasons.fmd_description',
    illustration: fmdIllustration,
  },
  animation: {
    titleKey: 'companies.pages.new-company.appointment_booking.encourage_reasons.animation_title',
    descriptionKey:
      'companies.pages.new-company.appointment_booking.encourage_reasons.animation_description',
    illustration: animationIllustration,
  },
  incitation: {
    titleKey: 'companies.pages.new-company.appointment_booking.encourage_reasons.incitation_title',
    descriptionKey:
      'companies.pages.new-company.appointment_booking.encourage_reasons.incitation_description',
    illustration: incitationIllustration,
  },
};

const customers: Array<{ icon: string; title: string }> = [
  { icon: edfIcon, title: 'EDF' },
  { icon: allianzIcon, title: 'Allianz' },
  { icon: onetIcon, title: 'ONET' },
  { icon: sncfIcon, title: 'SNCF' },
  { icon: franceTravailIcon, title: 'France Travail' },
];

function AppointmentBooking(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={5}
      justifyContent="center"
      minHeight="max(100svh, 100%)"
      paddingX={{ xs: 5, lg: 8 }}
      paddingY={5}
      width="100%"
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography>{t('companies.pages.new-company.appointment_booking.encourage')}</Typography>
        {reasons.map((key) => {
          const { illustration, titleKey, descriptionKey } = reasonsMap[key];

          return (
            <Card
              key={key}
              sx={{ bgcolor: '#fff', borderRadius: 2, position: 'relative', width: '100%' }}
              variant="outlined"
            >
              <CardContent
                sx={{
                  '&&': {
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    height: '100%',
                    paddingBottom: 2,
                  },
                }}
              >
                <img src={illustration} width={80} />
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <Typography fontSize="1.15rem" fontWeight={700}>
                    {t(titleKey)}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {t(descriptionKey)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography>{t('companies.pages.new-company.appointment_booking.customers')}</Typography>
        <Box display="flex">
          {customers.map(({ icon, title }, index) => (
            <img
              alt={`Logo ${title}`}
              key={index}
              src={icon}
              style={{ width: `${100 / customers.length}%` }}
              title={title}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default AppointmentBooking;
