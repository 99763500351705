import {
  Autocomplete,
  Place,
  TUserPlaceType,
  UserPlace,
  createNewImageMarker,
  useGeoveloMap,
  userPlaceTypes,
} from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { MutableRefObject, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import companyIcon from '../../assets/img/favorite-company.svg';

import { MapMouseEvent, Marker } from '!maplibre-gl';

export const placeTypes = { ...userPlaceTypes, company: { color: '#dd428d', icon: companyIcon } };

function PlaceForm({
  mapId,
  initialized: _initialized,
  isSubmitting,
  placeType,
  initialPlace,
  updatedPlace,
  markerRef,
  setUpdated,
  onPlaceUpdated,
}: {
  initialized: boolean;
  initialPlace?: { point?: GeoJSON.Point } | null;
  isSubmitting?: boolean;
  mapId: string;
  markerRef: MutableRefObject<Marker | null>;
  onPlaceUpdated?: (place: Place | null) => void;
  placeType: Extract<TUserPlaceType, 'home' | 'work'> | 'company';
  setUpdated?: (updated: boolean) => void;
  updatedPlace?: UserPlace | Place | null;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { geogroup, sites },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    map,
    init: initMap,
    destroy: destroyMap,
  } = useGeoveloMap({
    smallDevice: true,
    baseLayer: 'geovelo',
  });

  useEffect(() => {
    if (_initialized) setInitialized(true);
  }, [_initialized]);

  useEffect(() => {
    if (initialized) {
      initMap({
        container: mapId,
        customZoomControls: true,
        center: initialPlace?.point
          ? { lat: initialPlace.point.coordinates[1], lng: initialPlace.point.coordinates[0] }
          : geogroup?.place
            ? { lat: geogroup.place.point.coordinates[1], lng: geogroup.place.point.coordinates[0] }
            : undefined,
        zoom: initialPlace?.point || geogroup?.place ? 15 : undefined,
      });
    }

    return () => {
      markerRef.current = null;
      destroyMap();
    };
  }, [initialized]);

  useEffect(() => {
    function handleClick({ lngLat: { lat, lng } }: MapMouseEvent) {
      onPlaceUpdated?.(new Place(undefined, { type: 'Point', coordinates: [lng, lat] }));
    }

    if (!updatedPlace) map?.on('click', handleClick);

    if (map && updatedPlace) {
      if (markerRef.current) {
        markerRef.current?.setLngLat({
          lat: updatedPlace.point.coordinates[1],
          lng: updatedPlace.point.coordinates[0],
        });
      } else {
        const { icon, color } = placeTypes[placeType];
        markerRef.current = createNewImageMarker({
          color,
          icon,
          draggable: true,
        })
          .setLngLat({
            lat: updatedPlace.point.coordinates[1],
            lng: updatedPlace.point.coordinates[0],
          })
          .on('dragend', ({ target }: { target: Marker }) => {
            map.flyTo({ center: target.getLngLat(), zoom: Math.max(15, map.getZoom()) });
            onPlaceUpdated?.(
              new Place(undefined, {
                type: 'Point',
                coordinates: target.getLngLat().toArray(),
              }),
            );
            setUpdated?.(true);
          })
          .addTo(map);
      }
    } else {
      markerRef.current?.remove();
      markerRef.current = null;
    }

    return () => {
      map?.off('click', handleClick);
    };
  }, [map, updatedPlace]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontWeight={700} variant="body2">
          {t('companies.pages.admin.user.address_form.labels_address', {
            context: placeType,
          })}
        </Typography>
        <Autocomplete
          disableFloatingLabel
          customOptions={
            placeType === 'work'
              ? sites && sites.length === 0
                ? geogroup?.place
                  ? [geogroup.place]
                  : []
                : sites?.reduce<Place[]>((res, { place }) => {
                    if (place) res.push(place);
                    return res;
                  }, [])
              : undefined
          }
          defaultValue={updatedPlace || null}
          disabled={isSubmitting}
          label="Ex: 16 rue Saint-Merri 75004 Paris"
          margin="none"
          onSelect={(place) => {
            onPlaceUpdated?.(place);
            setUpdated?.(true);
            if (map && place) {
              const [lng, lat] = place.point.coordinates;
              map.flyTo({
                animate: false,
                center: { lat, lng },
                zoom: Math.max(15, map.getZoom()),
              });
            }
          }}
          size="small"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontWeight={700} variant="body2">
          {t('companies.pages.admin.user.address_form.labels_map')}
        </Typography>
        <Box height={200} id={mapId} />
      </Box>
    </Box>
  );
}

export default PlaceForm;
