import { Geogroup } from '@geovelo-frontends/commons';
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AppContext } from '../../app/context';
import { Button } from '../../components';
import { environment } from '../../environment';
import LoginLayout, { addCompanySteps, loginStep, registerStep } from '../../layouts/login';

export const jobs = ['rh', 'rse', 'dg', 'mobilityManager', 'bikeReferent', 'other'] as const;
export type TJob = (typeof jobs)[number];
export const jobLabels: { [key in TJob]: string } = {
  rh: 'Ressources Humaines',
  rse: 'RSE',
  mobilityManager: 'Responsable Mobilités',
  dg: 'Direction Générale',
  bikeReferent: 'Autre mais référent vélo officiel',
  other: 'Autre (veuillez préciser)',
};

export interface IValues {
  eulaAccepted: boolean;
  firstName: string;
  job: TJob | '';
  lastName: string;
  receiveNewsletter: boolean;
  otherJob: string;
  phoneNumber: string;
}

const phonePattern = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

function AddCompanyPersonalDataPage({
  isWebview,
  handleNext,
}: {
  handleNext?: (state: IValues) => void;
  isWebview?: boolean;
}): JSX.Element {
  const { state } = useLocation();
  const [initialized, setInitialized] = useState(false);
  const [geogroup] = useState<Geogroup | undefined>(state?.geogroup);
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();
  const { isValid, values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        firstName: state?.firstName || '',
        lastName: state?.lastName || '',
        job: state?.job || '',
        otherJob: state?.otherJob || '',
        phoneNumber: state?.phoneNumber || '',
        eulaAccepted: state?.eulaAccepted || false,
        receiveNewsletter: state?.receiveNewsletter || false,
      },
      validationSchema: Yup.object().shape({
        firstName: Yup.string().required(
          t('companies.pages.new-company.personal_data.first_name_required') || '',
        ),
        lastName: Yup.string().required(
          t('companies.pages.new-company.personal_data.last_name_required') || '',
        ),
        job: Yup.string().required(
          t('companies.pages.onboarding.personal_data.professional_email_required') || '',
        ),
        phoneNumber: Yup.string().matches(
          phonePattern,
          t('companies.pages.onboarding.personal_data.phone_required') || '',
        ),
        eulaAccepted: Yup.boolean().oneOf([true]),
      }),
      validateOnMount: true,
      validateOnChange: true,
      enableReinitialize: true,
      onSubmit,
    });

  useEffect(() => {
    if (!isWebview && currentPartner) navigate('/');
    else if (!isWebview && currentUser === null) {
      navigate('/subscription', {
        state: {
          isSubscription: true,
          eventCode: state?.eventCode,
          utmSource: state?.utmSource,
          utmCampaign: state?.utmCampaign,
        },
      });
    } else if (currentUser) {
      setInitialized(true);
    }
  }, [currentPartner, currentUser]);

  useEffect(() => {
    if (initialized && currentUser && state?.isLogin) {
      enqueueSnackbar(
        t('companies.pages.new-company.personal_data.connection_succeeded', {
          email: currentUser.email,
        }),
        {
          variant: 'success',
        },
      );
    }
  }, [initialized]);

  async function onSubmit({
    firstName,
    lastName,
    job,
    otherJob,
    phoneNumber,
    eulaAccepted,
    receiveNewsletter,
  }: IValues) {
    if (!firstName || !lastName || !eulaAccepted || (job !== 'other' && !phoneNumber)) return;

    const { isLogin, geogroup, geogroups, eventCode, utmSource, utmCampaign } = state || {};

    if (isWebview) {
      handleNext?.({
        firstName,
        lastName,
        job,
        otherJob,
        phoneNumber,
        eulaAccepted,
        receiveNewsletter,
      });
    } else if (!geogroup && geogroups && geogroups.length > 0) {
      navigate('/subscription/upgrade-community', {
        state: {
          isLogin: Boolean(isLogin),
          isSubscription: true,
          firstName,
          lastName,
          job,
          otherJob,
          phoneNumber,
          eulaAccepted,
          receiveNewsletter,
          geogroups,
          eventCode,
          utmSource,
          utmCampaign,
        },
      });
    } else {
      navigate('/subscription/company-data', {
        state: {
          isLogin: Boolean(isLogin),
          isSubscription: true,
          firstName,
          lastName,
          job,
          otherJob,
          phoneNumber,
          eulaAccepted,
          receiveNewsletter,
          geogroup,
          eventCode,
          utmSource,
          utmCampaign,
        },
      });
    }
  }

  if (currentPartner || currentUser === null) return <></>;

  return (
    <LoginLayout
      leftPanelType="stepper"
      stepIndex={isWebview ? 0 : 1}
      steps={
        isWebview
          ? addCompanySteps
          : [!state?.isLogin ? registerStep : loginStep, ...addCompanySteps]
      }
      title={t('companies.pages.new-company.personal_data.title', {
        context: geogroup ? 'upgrade' : '',
      })}
    >
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={5}
        marginTop={-3}
        onSubmit={handleSubmit}
      >
        <Typography>
          {t('companies.pages.new-company.personal_data.description', {
            context: geogroup ? 'upgrade' : '',
          })}
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            required
            error={touched.firstName && Boolean(errors.firstName)}
            id="firstName"
            InputLabelProps={{ shrink: true }}
            label={t('companies.pages.new-company.personal_data.first_name')}
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            size="small"
            value={values.firstName}
            variant="outlined"
          />
          <TextField
            required
            error={touched.lastName && Boolean(errors.lastName)}
            id="lastName"
            InputLabelProps={{ shrink: true }}
            label={t('companies.pages.new-company.personal_data.last_name')}
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            size="small"
            value={values.lastName}
            variant="outlined"
          />
          <Box display="flex" flexDirection="column" gap={1}>
            <FormControl required margin="none" size="small">
              <FormLabel component="legend" id="site-label">
                <Typography variant="caption">Fonction au sein de l'entreprise</Typography>
              </FormLabel>
              <Select
                id="job"
                labelId="job-label"
                name="job"
                onBlur={handleBlur}
                onChange={handleChange}
                renderValue={(key) => jobLabels[key]}
                size="small"
                value={values.job}
              >
                {jobs.map((key) => (
                  <MenuItem key={key} value={key}>
                    {jobLabels[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {values.job === 'other' && (
              <TextField
                required
                id="otherJob"
                InputLabelProps={{ shrink: true }}
                name="otherJob"
                onBlur={handleBlur}
                onChange={handleChange}
                size="small"
                value={values.otherJob}
                variant="outlined"
              />
            )}
          </Box>
          <Collapse in={values.job !== '' && values.job !== 'other'}>
            <Box marginTop={1}>
              <TextField
                fullWidth
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                id="phoneNumber"
                InputLabelProps={{ shrink: true }}
                label={t('companies.pages.new-company.personal_data.phone')}
                name="phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('companies.pages.new-company.personal_data.phone_placeholder')}
                required={values.job !== 'other'}
                size="small"
                value={values.phoneNumber}
                variant="outlined"
              />
            </Box>
          </Collapse>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.eulaAccepted}
                color="secondary"
                name="eulaAccepted"
                onBlur={handleBlur}
                onChange={handleChange}
                size="small"
                value={values.eulaAccepted}
              />
            }
            label={
              <Trans
                components={[
                  <a
                    href={environment.driveLinks.eulaUrl}
                    key={0}
                    rel="noreferrer"
                    style={{ color: palette.primary.main }}
                    target="_blank"
                  />,
                ]}
                i18nKey="companies.pages.register.approve_gcu"
              />
            }
            slotProps={{ typography: { variant: 'body2' } }}
          />
          {(isWebview || state?.isLogin) && (
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="receiveNewsletter"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.receiveNewsletter}
                />
              }
              label={t('companies.pages.admin.user.communication_form.receive_newsletter')}
              slotProps={{ typography: { variant: 'body2' } }}
            />
          )}
        </Box>
        <Button
          color="primary"
          disabled={!isValid || (values.job === 'other' ? !values.otherJob : !values.phoneNumber)}
          type="submit"
          variant="contained"
        >
          {t('commons.actions.next')}
        </Button>
      </Box>
    </LoginLayout>
  );
}

export default AddCompanyPersonalDataPage;
