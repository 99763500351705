import { PartnerService, TBackendPartnerCreationReason } from '@geovelo-frontends/commons';
import { Check } from '@mui/icons-material';
import { Box, CardActionArea, CardContent, DialogProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import animationIllustration from '../../assets/img/animation-illus.svg';
import fmdIllustration from '../../assets/img/fmd-illus.svg';
import incitationIllustration from '../../assets/img/incitation-illus.svg';
import challengeIllustration from '../../assets/img/trophy-illus-3.svg';
import { Button, Card, Dialog } from '../../components';

export const reasons = ['fmd', 'animation', 'incitation', 'challenge'] as const;

type TReason = (typeof reasons)[number];

export const reasonsMap: {
  [key in TReason]: {
    descriptionKey: string;
    illustration: string;
    key: TBackendPartnerCreationReason;
    titleKey: string;
  };
} = {
  fmd: {
    key: 'FMD_COMMUTING_COMPENSATION',
    titleKey: 'companies.creation_reason_dialog.choices.fmd_title',
    descriptionKey: 'companies.creation_reason_dialog.choices.fmd_description',
    illustration: fmdIllustration,
  },
  animation: {
    key: 'COMMUNITY_ANIMATION',
    titleKey: 'companies.creation_reason_dialog.choices.animation_title',
    descriptionKey: 'companies.creation_reason_dialog.choices.animation_description',
    illustration: animationIllustration,
  },
  incitation: {
    key: 'ENCOURAGE_CYCLING',
    titleKey: 'companies.creation_reason_dialog.choices.incitation_title',
    descriptionKey: 'companies.creation_reason_dialog.choices.incitation_description',
    illustration: incitationIllustration,
  },
  challenge: {
    key: 'CHALLENGE_EVENT',
    titleKey: 'companies.creation_reason_dialog.choices.challenge_title',
    descriptionKey: 'companies.creation_reason_dialog.choices.challenge_description',
    illustration: challengeIllustration,
  },
};

function CreationReasonDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
}): JSX.Element {
  const [creationReasons, setCreationReasons] = useState<TBackendPartnerCreationReason[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    partner: { current: currentPartner },
    actions: { setUserCreationReasonDialogSeen },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.open) setUserCreationReasonDialogSeen(true);
  }, [props.open]);

  async function handleSubmit(creationReasons: TBackendPartnerCreationReason[]) {
    if (!currentPartner || creationReasons.length === 0) return;

    setLoading(true);

    try {
      await PartnerService.editPartner(currentPartner, { creationReasons });
    } catch (err) {
      enqueueSnackbar(t('companies.creation_reason_dialog.server_error'), { variant: 'error' });
    }

    setLoading(false);
    props.onClose();
  }

  return (
    <Dialog disableBackgroundClick disableCloseButton fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          {t('companies.creation_reason_dialog.title')}
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          {reasons.map((key) => {
            const { key: backendKey, illustration, titleKey, descriptionKey } = reasonsMap[key];
            const active = creationReasons.includes(backendKey);

            return (
              <Card
                key={key}
                sx={{
                  borderRadius: 2,
                  position: 'relative',
                  width: '100%',
                  ...(active ? { bgcolor: '#ecfbf4', borderColor: '#96e2c1' } : {}),
                }}
                variant="outlined"
              >
                <CardActionArea
                  onClick={() =>
                    setCreationReasons(
                      active
                        ? [...creationReasons].filter((reason) => reason !== backendKey)
                        : [...creationReasons, backendKey],
                    )
                  }
                  sx={{ height: '100%' }}
                >
                  <CardContent
                    sx={{
                      '&&': {
                        alignItems: 'center',
                        display: 'flex',
                        gap: 2,
                        height: '100%',
                        paddingBottom: 2,
                      },
                    }}
                  >
                    <img src={illustration} width={80} />
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                      <Typography fontSize="1.15rem" fontWeight={700}>
                        {t(titleKey)}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {t(descriptionKey)}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
                {active && (
                  <Box position="absolute" right={4} top={4}>
                    <Check fontSize="small" sx={{ color: '#2ac682' }} />
                  </Box>
                )}
              </Card>
            );
          })}
        </Box>
        <Box alignItems="center" display="flex" gap={2} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={loading}
            onClick={() => handleSubmit(['OTHER'])}
            variant="text"
          >
            {t('companies.creation_reason_dialog.actions.ignore')}
          </Button>
          <Button
            color="primary"
            disabled={loading || creationReasons.length === 0}
            onClick={() => handleSubmit(creationReasons)}
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default CreationReasonDialog;
