import { Close } from '@mui/icons-material';
import {
  Box,
  CardContent,
  CardProps,
  Divider,
  IconButton,
  Card as MuiCard,
  Tooltip,
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export function Card({
  fullHeight,
  disableScoll,
  bgColor,
  disableDivider,
  variant,
  header,
  children,
  onDismiss,
  ...props
}: {
  bgColor?: string;
  children: ReactNode;
  disableDivider?: boolean;
  disableScoll?: boolean;
  fullHeight?: boolean;
  header?: ReactNode;
  onDismiss?: () => void;
  variant?: 'outlined';
} & Omit<CardProps, 'title'>): JSX.Element {
  const { t } = useTranslation();

  return (
    <MuiCard
      elevation={0}
      id={props.id}
      sx={{
        bgcolor: bgColor || '#fff',
        borderRadius: 4,
        ...(fullHeight ? { display: 'flex', flexDirection: 'column', flexGrow: 1 } : {}),
        ...(disableScoll ? { overflow: 'hidden' } : {}),
        ...props.sx,
      }}
      variant={variant || 'elevation'}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...(fullHeight ? { display: 'flex', flexDirection: 'column', flexGrow: 1 } : {}),
          ...(disableScoll ? { overflow: 'hidden' } : {}),
          padding: 0,
          '&:last-child': { paddingBottom: 0 },
        }}
      >
        {header && (
          <>
            <Box alignItems="flex-start" display="flex" gap={3} paddingX={5} paddingY={3}>
              <Box
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                columnGap={5}
                display="flex"
                flexDirection={{ xs: 'column', lg: 'row' }}
                flexGrow={1}
                minHeight={36.3}
                rowGap={1}
              >
                {header}
              </Box>
              {onDismiss && (
                <Box alignItems="center" display="flex" flexShrink={0} height={32}>
                  <Tooltip title={t('commons.actions.close')}>
                    <IconButton onClick={onDismiss} size="small">
                      <Close color="action" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            {!disableDivider && variant !== 'outlined' && <Divider />}
          </>
        )}
        {children}
      </CardContent>
    </MuiCard>
  );
}
