import { InfoOutlined } from '@mui/icons-material';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowInward, ArrowOutward } from './icons';

function NewStatCard({
  bgColor,
  label,
  infoLabel,
  value,
  enableProgression,
  progression,
}: {
  bgColor: string;
  enableProgression?: boolean;
  infoLabel?: ReactNode;
  label: ReactNode;
  progression?: number;
  value: ReactNode;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="flex-start"
      bgcolor={bgColor}
      borderRadius={2}
      display="flex"
      flexDirection="column"
      gap={1}
      padding={3}
    >
      <Box
        alignItems="center"
        alignSelf="stretch"
        display="flex"
        gap={1}
        justifyContent="space-between"
      >
        <Typography variant="body2">{label}</Typography>
        {infoLabel && (
          <Tooltip title={infoLabel}>
            <InfoOutlined color="action" sx={{ height: 16, width: 16 }} />
          </Tooltip>
        )}
      </Box>
      <Typography fontWeight={700} sx={{ '> span': { fontSize: '0.875rem' } }} variant="h5">
        {value === undefined ? <Skeleton variant="text" width={100} /> : value}
      </Typography>
      {enableProgression && progression !== 0 && (
        <Box alignItems="center" display="flex" gap={0.5}>
          {progression ? (
            <>
              {progression > 0 ? (
                <ArrowOutward color="action" sx={{ height: 16, width: 16 }} />
              ) : (
                <ArrowInward color="action" sx={{ height: 16, width: 16 }} />
              )}
              <Typography color="textSecondary" lineHeight={1} variant="caption">
                {Math.abs(progression)}% vs {t('commons.periods.last_month').toLocaleLowerCase()}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton height={16} variant="circular" width={16} />
              <Typography color="textSecondary" lineHeight={1} variant="caption">
                <Skeleton variant="text" width={150} />
              </Typography>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default NewStatCard;
