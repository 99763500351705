import { CompanyIcon, Geogroup } from '@geovelo-frontends/commons';
import { Avatar, Box, ButtonBase, Radio, Typography } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { Button } from '../../components';
import LoginLayout, { addCompanySteps, loginStep, registerStep } from '../../layouts/login';

function UpgradeCommunityPage(): JSX.Element {
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    state,
  }: {
    state: {
      eulaAccepted?: boolean;
      eventCode?: string;
      firstName?: string;
      geogroups?: Array<Geogroup & { joinDate: string }>;
      isLogin?: boolean;
      job?: string;
      lastName?: string;
      otherJob?: string;
      receiveNewsletter?: boolean;
      utmSource?: string;
      utmCampaign?: string;
    } | null;
  } = useLocation();
  const [selectedGeogroup, selectGeogroup] = useState<Geogroup | null>(
    state?.geogroups?.[0] || null,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser === null) {
      navigate('/subscription', {
        state: {
          isSubscription: true,
          eventCode: state?.eventCode,
          utmSource: state?.utmSource,
          utmCampaign: state?.utmCampaign,
        },
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const {
      isLogin,
      geogroups,
      firstName,
      lastName,
      job,
      otherJob,
      eulaAccepted,
      receiveNewsletter,
      eventCode,
      utmSource,
      utmCampaign,
    } = state || {};
    if (!geogroups || geogroups.length === 0) {
      navigate('/subscription/company-data', {
        state: {
          isSubscription: true,
          isLogin: Boolean(isLogin),
          firstName,
          lastName,
          job,
          otherJob,
          eulaAccepted,
          receiveNewsletter,
          eventCode,
          utmSource,
          utmCampaign,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (currentPartner) navigate('/');
  }, [currentPartner]);

  const geogroups = state?.geogroups || [];

  if (currentUser === null || geogroups.length === 0 || !selectedGeogroup) return <></>;

  return (
    <LoginLayout
      leftPanelType="stepper"
      stepIndex={2}
      steps={[!state?.isLogin ? registerStep : loginStep, ...addCompanySteps]}
      title={t('companies.pages.upgrade_community.title', { count: geogroups.length })}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        {geogroups.length === 1 && <CommunityCard geogroup={geogroups[0]} />}
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography fontWeight={600}>
            {t('companies.pages.upgrade_community.subtitle_upgrade', {
              count: geogroups.length,
            })}
          </Typography>
          <Typography variant="body2">
            {t('companies.pages.upgrade_community.description_upgrade')}
          </Typography>
        </Box>
        {geogroups.length > 1 && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontWeight={600}>
              {t('companies.pages.upgrade_community.title_select_community')}
            </Typography>
            {geogroups.map((geogroup) => (
              <CommunityCard
                geogroup={geogroup}
                key={geogroup.id}
                onSelect={selectGeogroup}
                selected={geogroup.id === selectedGeogroup.id}
              />
            ))}
          </Box>
        )}
      </Box>
      <Box alignItems="stretch" display="flex" flexDirection="column" gap={2}>
        <Button
          color="primary"
          component={Link}
          state={{ ...state, geogroups: undefined, geogroup: selectedGeogroup }}
          to="/subscription/company-data"
          variant="contained"
        >
          {t('companies.pages.upgrade_community.actions.upgrade')}
        </Button>
        <Button
          color="primary"
          component={Link}
          state={{ ...state, geogroups: undefined }}
          to="/subscription/company-data"
          variant="outlined"
        >
          {t('companies.pages.upgrade_community.actions.create_new')}
        </Button>
      </Box>
    </LoginLayout>
  );
}

function CommunityCard({
  selected,
  geogroup,
  onSelect,
}: {
  geogroup: Geogroup & { joinDate: string };
  selected?: boolean;
  onSelect?: (geogroup: Geogroup) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      bgcolor={selected ? '#EEF9F4' : undefined}
      borderRadius={4}
      component={onSelect ? ButtonBase : 'div'}
      display="flex"
      gap={2}
      justifyContent="flex-start"
      onClick={onSelect ? () => onSelect(geogroup) : undefined}
      padding={2}
    >
      <Box flexShrink={0}>
        {geogroup.photo ? (
          <Avatar src={geogroup.photo} />
        ) : (
          <Avatar
            sx={({ palette }) => ({
              bgcolor: selected ? '#03825C' : onSelect ? undefined : palette.primary.main,
              color: selected || !onSelect ? palette.primary.contrastText : undefined,
            })}
          >
            <CompanyIcon />
          </Avatar>
        )}
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Typography
          align="left"
          color={selected ? '#03825C' : onSelect ? undefined : 'primary'}
          fontWeight={600}
          variant="body2"
        >
          {geogroup.title} (
          {t('companies.pages.upgrade_community.members', { count: geogroup.nbMembers || 0 })})
        </Typography>
        <Typography align="left" variant="caption">
          {t('commons.created_on_fem', { created: moment(geogroup.joinDate).format('LL') })}
        </Typography>
      </Box>
      {onSelect && (
        <Box flexShrink={0}>
          <Radio checked={selected || false} color="secondary" size="small" />
        </Box>
      )}
    </Box>
  );
}

export default UpgradeCommunityPage;
