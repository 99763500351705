import { SvgIcon, SvgIconProps } from '@mui/material';

export function CertificatesIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M12.6667 3.33333V12.6667H3.33333V3.33333H12.6667ZM12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2Z"
        fill="currentColor"
      />
      <path
        d="M9.33341 11.3334H4.66675V10.0001H9.33341V11.3334ZM11.3334 8.66675H4.66675V7.33341H11.3334V8.66675ZM11.3334 6.00008H4.66675V4.66675H11.3334V6.00008Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
