import { SvgIcon, SvgIconProps } from '@mui/material';

export function EyeIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M19.9374 11.7C18.4229 8.1825 15.5739 6 12.5 6C9.42607 6 6.57706 8.1825 5.06259 11.7C5.02131 11.7946 5 11.8968 5 12C5 12.1032 5.02131 12.2054 5.06259 12.3C6.57706 15.8175 9.42607 18 12.5 18C15.5739 18 18.4229 15.8175 19.9374 12.3C19.9787 12.2054 20 12.1032 20 12C20 11.8968 19.9787 11.7946 19.9374 11.7ZM12.5 16.5C10.1233 16.5 7.87411 14.7825 6.57706 12C7.87411 9.2175 10.1233 7.5 12.5 7.5C14.8767 7.5 17.1259 9.2175 18.4229 12C17.1259 14.7825 14.8767 16.5 12.5 16.5ZM12.5 9C11.9069 9 11.327 9.17595 10.8339 9.50559C10.3407 9.83524 9.95631 10.3038 9.72933 10.8519C9.50234 11.4001 9.44295 12.0033 9.55867 12.5853C9.67438 13.1672 9.96001 13.7018 10.3794 14.1213C10.7988 14.5409 11.3332 14.8266 11.9149 14.9424C12.4967 15.0581 13.0997 14.9987 13.6477 14.7716C14.1956 14.5446 14.664 14.1601 14.9935 13.6667C15.3231 13.1734 15.499 12.5933 15.499 12C15.499 11.2044 15.183 10.4413 14.6206 9.87868C14.0582 9.31607 13.2954 9 12.5 9ZM12.5 13.5C12.2034 13.5 11.9135 13.412 11.6669 13.2472C11.4203 13.0824 11.2282 12.8481 11.1147 12.574C11.0012 12.2999 10.9715 11.9983 11.0293 11.7074C11.0872 11.4164 11.23 11.1491 11.4397 10.9393C11.6494 10.7296 11.9166 10.5867 12.2075 10.5288C12.4983 10.4709 12.7998 10.5006 13.0738 10.6142C13.3478 10.7277 13.582 10.92 13.7468 11.1666C13.9115 11.4133 13.9995 11.7033 13.9995 12C13.9995 12.3978 13.8415 12.7794 13.5603 13.0607C13.2791 13.342 12.8977 13.5 12.5 13.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
