import { Geogroup, Place, PlaceIcon, UserPlace } from '@geovelo-frontends/commons';
import {
  Autocomplete,
  Box,
  Collapse,
  FilterOptionsState,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import distance from '@turf/distance';
import { MutableRefObject, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';

import PlaceForm from './form';

import { Marker } from '!maplibre-gl';

export const noCorrespondingSiteItem = {
  id: 'noCorrespondingSite',
  titleKey: 'companies.pages.admin.user.teams_form.no_match',
} as const;

type TOption = Geogroup | typeof noCorrespondingSiteItem;

export type TSelectedSite = Geogroup | typeof noCorrespondingSiteItem | null;

function WorkForm({
  initialized,
  mapId,
  markerRef,
  size,
  disableLabel,
  isSubmitting,
  initialPlace,
  selectedSite,
  disableNearHome,
  disableNoCorrespondingSite,
  updatedPlace,
  selectSite,
  setUpdated,
  onPlaceUpdated,
}: {
  disableLabel?: boolean;
  disableNoCorrespondingSite?: boolean;
  disableNearHome?: boolean;
  isSubmitting?: boolean;
  initialized: boolean;
  initialPlace?: { point?: GeoJSON.Point; siteId?: number | null } | null;
  mapId?: string;
  markerRef?: MutableRefObject<Marker | null>;
  onPlaceUpdated?: (place: Place | null) => void;
  selectedSite: TSelectedSite;
  selectSite: (site: TSelectedSite) => void;
  setUpdated?: (updated: boolean) => void;
  size?: 'small';
  updatedPlace?: UserPlace | Place | null;
}): JSX.Element {
  const [search, setSearch] = useState('');
  const [sites, setSites] = useState<Geogroup[]>([]);
  const {
    partner: { sites: _sites },
    user: { home },
  } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    setSites(
      _sites?.sort((a, b) => {
        if (!disableNearHome && home) {
          if (a.place && b.place)
            return distance(a.place.point, home.point) - distance(b.place.point, home.point);
          if (a.place) return -1;
          if (b.place) return 1;
        }

        return b.nbMembers - a.nbMembers;
      }) || [],
    );
  }, [home, _sites]);

  const noCorrespondingSite = (selectedSite && selectedSite.id === 'noCorrespondingSite') || false;

  function filterOptions(options: TOption[], state: FilterOptionsState<TOption>) {
    const filteredOptions = createFilterOptions<TOption>({
      limit: 3,
      ignoreAccents: true,
    })(options, state);

    if (!disableNoCorrespondingSite && state.inputValue)
      filteredOptions.push(noCorrespondingSiteItem);

    return filteredOptions;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Autocomplete
        disabled={isSubmitting}
        filterOptions={filterOptions}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => ('titleKey' in option ? t(option.titleKey) : option.title)}
        groupBy={() => ''}
        id="sites-autocomplete"
        inputValue={search}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText={t('companies.pages.admin.user.teams_form.no_option_site')}
        onChange={(_, value) => {
          onPlaceUpdated?.(!value || value.id === 'noCorrespondingSite' ? null : value.place);
          setUpdated?.(true);
          selectSite(value);
        }}
        onInputChange={(_, value) => setSearch(value)}
        options={sites}
        renderGroup={({ key, children }) => (
          <li key={key}>
            {!disableNearHome && !search && home && (
              <Box alignItems="center" display="flex" height={32} paddingX={3}>
                <Typography color="textSecondary" variant="body2">
                  {t('companies.pages.admin.user.teams_form.near_home')}
                </Typography>
              </Box>
            )}
            <ul style={{ padding: 0 }}>{children}</ul>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
            InputProps={{
              ...params.InputProps,
              sx: size === 'small' ? { fontSize: '0.875rem' } : {},
            }}
            label={
              !disableLabel ? t('companies.pages.admin.user.teams_form.labels_site') : undefined
            }
            margin="none"
            placeholder={t('commons.actions.search')}
            size="small"
          />
        )}
        renderOption={({ key, ...itemProps }, option) => {
          if (option.id === 'noCorrespondingSite') {
            return (
              <Box
                key={key}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(itemProps as any)}
                alignItems="center"
                display="flex"
                height={52}
              >
                <Typography color="primary" marginX={1} variant="body2">
                  {t(option.titleKey)}
                </Typography>
              </Box>
            );
          }

          return (
            <Box
              alignItems="center"
              display="flex"
              gap={2}
              key={key}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...(itemProps as any)}
            >
              {size !== 'small' && <PlaceIcon color="primary" />}
              <Box display="flex" flexDirection="column">
                <Typography variant="body2">{option.title}</Typography>
                {option.place?.address && size !== 'small' && (
                  <Typography color="textSecondary" variant="caption">
                    {option.place.address}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        }}
        value={selectedSite}
      />
      {!disableNoCorrespondingSite && mapId && markerRef && (
        <Collapse in={noCorrespondingSite}>
          <Box marginTop={2}>
            <PlaceForm
              initialized={initialized}
              initialPlace={initialPlace}
              isSubmitting={isSubmitting}
              mapId={mapId}
              markerRef={markerRef}
              onPlaceUpdated={onPlaceUpdated}
              placeType="work"
              setUpdated={setUpdated}
              updatedPlace={updatedPlace}
            />
          </Box>
        </Collapse>
      )}
    </Box>
  );
}

export default WorkForm;
