import {
  GeocoderService,
  GeogroupService,
  Place,
  TUserPlaceType,
  UserPlace,
  UserService,
  useCancellablePromise,
  userPlaceTypes,
} from '@geovelo-frontends/commons';
import { Box, CircularProgress, DialogProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import { Button } from '../button';
import { Dialog } from '../dialog';

import PlaceForm from './form';
import WorkForm, { TSelectedSite, noCorrespondingSiteItem } from './work-form';

import { Marker } from '!maplibre-gl';

const defaultMapId = 'place-map';

function PlaceDialog({
  mapId: _mapId,
  placeType,
  currentPlace,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  currentPlace: UserPlace | Place | null;
  mapId?: string;
  onClose: (place?: UserPlace | Place) => void;
  placeType: Extract<TUserPlaceType, 'home' | 'work'> | 'company';
}): JSX.Element {
  const [selectedSite, selectSite] = useState<TSelectedSite>(null);
  const [updatedPlace, updatePlace] = useState<UserPlace | Place | null>(null);
  const [updated, setUpdated] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const markerRef = useRef<Marker | null>(null);
  const {
    partner: { current: currentPartner, geogroup, sites },
    user: { home, works },
    actions: { setPartnerGeogroup, setUserHome, setUserWorks },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (props.open) {
      if (placeType === 'work') {
        selectSite(
          currentPlace
            ? (currentPlace instanceof UserPlace &&
                currentPlace.siteId &&
                sites?.find(({ id }) => id === currentPlace.siteId)) ||
                noCorrespondingSiteItem
            : null,
        );
      }

      updatePlace(currentPlace?.clone() || null);
    }

    return () => {
      if (props.open) {
        setTimeout(() => {
          selectSite(null);
          setUpdated(false);
          setSubmitting(false);
        }, transitions.duration.leavingScreen);
      }
    };
  }, [props.open]);

  useEffect(() => {
    async function reverseGeocode() {
      if (updatedPlace && !updatedPlace.addressDetail) {
        try {
          const newPlace = await cancellablePromise(
            GeocoderService.reverseGeocode(undefined, updatedPlace.point),
          );
          updatePlace(newPlace);
        } catch (err) {
          console.error(err);
        }
      }
    }

    reverseGeocode();

    return () => cancelPromises();
  }, [updatedPlace]);

  async function handleSubmit() {
    if (placeType === 'company') {
      if (!currentPartner || !geogroup || !updatedPlace) return;

      setSubmitting(true);
      markerRef.current?.setDraggable(false);

      try {
        const updatedGeogroup = await cancellablePromise(
          GeogroupService.updateGeogroup(geogroup.id, { address: updatedPlace }, currentPartner),
        );

        setPartnerGeogroup(updatedGeogroup);
        enqueueSnackbar(t('companies.pages.admin.company.data.general_data_form.success'));
        props.onClose();
      } catch (err) {
        setSubmitting(false);
        markerRef.current?.setDraggable(true);
        enqueueSnackbar(t('companies.pages.admin.company.data.general_data_form.server_error'), {
          variant: 'error',
        });
      }
    } else {
      const currentUserPlace =
        placeType === 'home'
          ? home
          : works
            ? works.find(({ id }) => currentPlace?.id === id) || null
            : undefined;

      if (!geogroup || currentUserPlace === undefined || !updatedPlace) return;

      const { point, address } = updatedPlace;

      setSubmitting(true);
      markerRef.current?.setDraggable(false);

      try {
        let newPlace: UserPlace | undefined;
        if (currentUserPlace) {
          if (updated) {
            const { id } = currentUserPlace;
            if (typeof id === 'number') {
              newPlace = await UserService.updatePlace(id, {
                point,
                address,
                title: t(userPlaceTypes[placeType].titleKey || ''),
              });

              if (placeType === 'home') setUserHome(newPlace);
              else if (works) {
                const newWorks = [...works];
                const index = newWorks.findIndex((work) => id === work.id);
                if (index > -1) {
                  newWorks.splice(index, 1, newPlace);
                  setUserWorks(newWorks);
                }
              }
            }
          }
        } else {
          newPlace = await UserService.addPlace({
            type: placeType,
            point,
            address,
            title: t(userPlaceTypes[placeType].titleKey || ''),
          });

          if (placeType === 'home') setUserHome(newPlace);
          else if (works) {
            setUserWorks([...works, newPlace]);
          }
        }

        enqueueSnackbar(t('companies.pages.admin.user.address_form.success'));
        props.onClose(newPlace);
      } catch (err) {
        setSubmitting(false);
        markerRef.current?.setDraggable(true);
        enqueueSnackbar(t('companies.pages.admin.user.address_form.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  const mapId = _mapId || defaultMapId;

  return (
    <Dialog fullWidth loading={isSubmitting} maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          {placeType === 'company'
            ? t('companies.pages.admin.company.data.address_form.title', {
                context: currentPlace ? 'update' : 'creation',
              })
            : t(
                placeType === 'home'
                  ? 'companies.pages.admin.user.address_form.title_home'
                  : 'companies.pages.admin.user.address_form.title_work',
                { context: currentPlace ? 'update' : 'creation' },
              )}
        </Typography>
        {placeType !== 'company' && (
          <Typography sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }} variant="body2">
            {t('companies.pages.admin.user.address_form.description', {
              context: placeType === 'work' ? 'site' : '',
            })
              .split('\n')
              .map((line, index) => (
                <span key={index}>{line}</span>
              ))}
          </Typography>
        )}
        {placeType === 'work' ? (
          <WorkForm
            initialized={props.open}
            initialPlace={currentPlace}
            isSubmitting={isSubmitting}
            mapId={mapId}
            markerRef={markerRef}
            onPlaceUpdated={updatePlace}
            selectedSite={selectedSite}
            selectSite={selectSite}
            setUpdated={setUpdated}
            updatedPlace={updatedPlace}
          />
        ) : (
          <PlaceForm
            initialized={props.open}
            initialPlace={currentPlace}
            isSubmitting={isSubmitting}
            mapId={mapId}
            markerRef={markerRef}
            onPlaceUpdated={updatePlace}
            placeType={placeType}
            setUpdated={setUpdated}
            updatedPlace={updatedPlace}
          />
        )}
        <Box alignItems="center" display="flex" gap={2} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={() => props.onClose()}
            variant="outlined"
          >
            {t('commons.actions.cancel')}
          </Button>
          <Button
            color="primary"
            disabled={!updatedPlace || isSubmitting}
            onClick={handleSubmit}
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="contained"
          >
            {t('commons.actions.validate')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default PlaceDialog;
