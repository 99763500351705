import {
  AuthService,
  CyclabilityZoneService,
  Employee,
  EmployeeService,
  FMDParametersService,
  Geogroup,
  GeogroupService,
  MobilitySurveyService,
  Partner,
  PartnerService,
  TPartnerContract,
} from '@geovelo-frontends/commons';
import { useContext } from 'react';

import { AppContext, TOffer, _offers as offers, offersMap } from '../app/context';

const contractsOrders: { [key: string]: number } = {
  'geovelo-entreprise-premium': 1,
  'geovelo-entreprise-standard': 3,
  'geovelo-entreprise-free': 4,
};

export default function useEmployees({
  isWebview,
  isOnboardingWebview,
  isAddCompanyWebview,
  cancellablePromise,
}: {
  cancellablePromise: <T>(p: Promise<T>) => Promise<T>;
  isAddCompanyWebview?: boolean;
  isOnboardingWebview?: boolean;
  isWebview?: boolean;
}) {
  const {
    actions: {
      setCurrentPartner,
      setPartnerContract,
      setPartnerInvitationCode,
      setPartnerGeogroup,
      setPartnerSubgroupTypes,
      setPartnerTeams,
      setPartnerSubsidiaries,
      setPartnerRegions,
      setPartnerSites,
      setPartnerEmployeesCount,
      setPartnerInactiveEmployees,
      setPartnerFMDConfig,
      setPartnerMobilitySurveys,
      setPartnerPermissions,
      setPartnerHasChallenges,
      setPartnerAvailableEvents,
      setCurrentUser,
      setUserEmployee,
      setUserTeam,
      setUserSite,
      setUserTransportHabits,
      setUserCreationReasonDialogSeen,
      getPartnerEmployees,
      getAvailableEvents,
    },
  } = useContext(AppContext);

  async function getPartnerGeogroup(partner: Partner): Promise<Geogroup> {
    if (!partner.geoGroupId) {
      setPartnerGeogroup(null);
      throw new Error('partner has no geogroup');
    }

    try {
      const geogroup = await cancellablePromise(
        GeogroupService.getGeogroup({ geoGroupId: partner.geoGroupId }),
      );

      if (geogroup.place) {
        const {
          zones: [country],
        } = await CyclabilityZoneService.getZones({
          administrativeLevel: 'COUNTRY',
          point: geogroup.place.point,
        });

        geogroup.countryCode = (country && country.countryCode) || null;
      } else geogroup.countryCode = null;

      setPartnerGeogroup(geogroup);
      if (!isWebview) getPartnerInvitationCode(partner);

      return geogroup;
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);

        try {
          const { group: geogroup } = await GeogroupService.joinGeogroup(partner.geoGroupId);

          if (geogroup.place) {
            const {
              zones: [country],
            } = await CyclabilityZoneService.getZones({
              administrativeLevel: 'COUNTRY',
              point: geogroup.place.point,
            });

            geogroup.countryCode = (country && country.countryCode) || null;
          } else geogroup.countryCode = null;

          setPartnerGeogroup(geogroup);
          if (!isWebview) getPartnerInvitationCode(partner);

          return geogroup;
        } catch (err) {
          setPartnerGeogroup(null);
          throw new Error('partner geogroup is not joinable');
        }
      } else {
        throw err;
      }
    }
  }

  async function getEmployeeSubGroups(partner: Partner, teamsEnabled: boolean) {
    if (!partner.geoGroupId) {
      setUserTeam(null);
      setUserSite(null);
      return;
    }

    try {
      const geogroups = await cancellablePromise(
        GeogroupService.getUserGeogroups({ parentId: partner.geoGroupId }),
      );

      if (teamsEnabled) {
        setUserTeam(geogroups.find(({ group }) => group.subGroupType === 'companyTeam') || null);
      } else {
        setUserTeam(null);
      }

      setUserSite(
        geogroups.find(
          ({ status, group }) =>
            (status === 'manuallyJoin' || status === 'automaticallyJoin') &&
            group.subGroupType === 'companySite',
        ) || null,
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setUserTeam(null);
        setUserSite(null);
      }
    }
  }

  async function getEmployeeTransportHabits(employee: Employee) {
    try {
      const habits = await cancellablePromise(
        EmployeeService.getTransportHabits({ employeeId: employee.id }),
      );

      setUserTransportHabits(habits);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setUserTransportHabits(null);
      }
    }
  }

  async function getPartnerInvitationCode(partner: Partner) {
    if (!partner.geoGroupId) {
      setPartnerInvitationCode(null);
      return;
    }

    try {
      const { code } = await cancellablePromise(
        GeogroupService.getInvitationLink(partner.geoGroupId),
      );

      setPartnerInvitationCode(code);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setPartnerInvitationCode(null);
      }
    }
  }

  async function getPartnerSubGroups(partner: Partner, teamsEnabled: boolean) {
    if (!partner.geoGroupId) {
      setPartnerSubgroupTypes(teamsEnabled ? ['companySite', 'companyTeam'] : ['companySite']);
      setPartnerTeams(null);
      setPartnerSubsidiaries(null);
      setPartnerRegions(null);
      setPartnerSites(null);
      return;
    }

    try {
      const subgroupTypes = (
        await GeogroupService.getSubgroupTypes({ groupId: partner.geoGroupId })
      ).filter((key) => {
        if (key === 'companyTeam' && !teamsEnabled) return false;

        return true;
      });

      const { count, geogroups } = await cancellablePromise(
        GeogroupService.getGeogroups({
          parentId: partner.geoGroupId,
          types: subgroupTypes,
          pageSize: 100,
          page: 1,
        }),
      );

      const nbPages = Math.ceil(count / 100);
      if (nbPages > 1) {
        const _geogroups = (
          await Promise.all(
            new Array(nbPages - 1).fill(null).map((_, index) =>
              GeogroupService.getGeogroups({
                parentId: partner.geoGroupId || undefined,
                types: subgroupTypes,
                pageSize: 100,
                page: 2 + index,
              }),
            ),
          )
        ).flatMap(({ geogroups }) => geogroups);

        geogroups.push(..._geogroups);
      }

      setPartnerSubgroupTypes(subgroupTypes);

      setPartnerTeams(
        subgroupTypes.includes('companyTeam')
          ? geogroups
              .filter(({ subGroupType }) => subGroupType === 'companyTeam')
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
          : null,
      );

      setPartnerSubsidiaries(
        subgroupTypes.includes('companySubsidiary')
          ? geogroups
              .filter(({ subGroupType }) => subGroupType === 'companySubsidiary')
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
          : null,
      );

      setPartnerRegions(
        subgroupTypes.includes('companyRegion')
          ? geogroups
              .filter(({ subGroupType }) => subGroupType === 'companyRegion')
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
          : null,
      );

      setPartnerSites(
        geogroups
          .filter(({ subGroupType }) => subGroupType === 'companySite')
          .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())),
      );
    } catch (err) {
      if (!(err instanceof Error) || err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setPartnerSubgroupTypes(teamsEnabled ? ['companySite', 'companyTeam'] : ['companySite']);
        setPartnerTeams(null);
        setPartnerSubsidiaries(null);
        setPartnerRegions(null);
        setPartnerSites(null);
      }
    }
  }

  async function getPartnerFMDConfig(partner: Partner) {
    try {
      const [fmdConfig] = await cancellablePromise(FMDParametersService.getConfigurations(partner));

      setPartnerFMDConfig(fmdConfig || null);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setPartnerFMDConfig(null);
      }
    }
  }

  async function getPartnerMobilitySurveys(partner: Partner) {
    try {
      const surveys = await cancellablePromise(MobilitySurveyService.getSurveys({ partner }));

      setPartnerMobilitySurveys(surveys);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setPartnerMobilitySurveys([]);
      }
    }
  }

  async function signOut() {
    await AuthService.signOut();
    setCurrentUser(null);
  }

  async function selectEmployee(employee: Employee, _partner: Partner) {
    const contract: TPartnerContract = _partner.contracts
      ?.filter(
        ({ contractTemplate: { code }, endDateTime }) =>
          code && offers.includes(code) && endDateTime,
      )
      .sort(
        (a, b) =>
          contractsOrders[a.contractTemplate.code] - contractsOrders[b.contractTemplate.code],
      )[0] as TPartnerContract;

    if (!contract) {
      if (!isOnboardingWebview && !isAddCompanyWebview) signOut();
      return;
    }

    const {
      contractTemplate: { code: offer },
    } = contract;

    if (isWebview) employee.role = 'employee';

    const partner = await cancellablePromise(
      PartnerService.getPartner({ isAdmin: employee.isAdminOrAnimator, partner: _partner }),
    );

    setCurrentPartner(partner);
    const geogroup = await getPartnerGeogroup(partner);

    if (employee.isAdmin) {
      getPartnerEmployees(partner);
      getAvailableEvents(partner, geogroup);
    } else if (employee.isAdminOrAnimator) {
      setPartnerEmployeesCount(undefined);
      setPartnerInactiveEmployees(null);
      getAvailableEvents(partner, geogroup);
    } else {
      setPartnerEmployeesCount(undefined);
      setPartnerInactiveEmployees(null);
      setPartnerHasChallenges(false);
      setPartnerAvailableEvents({ current: [], future: [] });
    }

    const { permissions } = offersMap[offer as TOffer];
    setPartnerContract(contract);

    if (geogroup.countryCode !== 'fr') permissions.accessibilityEnabled = false;

    const { teamsEnabled, mobilitySurveyEnabled } = permissions;

    getEmployeeSubGroups(partner, teamsEnabled);
    getEmployeeTransportHabits(employee);
    setUserCreationReasonDialogSeen(true);
    getPartnerSubGroups(partner, teamsEnabled);
    if (!isOnboardingWebview && !isAddCompanyWebview) getPartnerFMDConfig(partner);
    if (mobilitySurveyEnabled && employee.isAdmin && !isWebview) getPartnerMobilitySurveys(partner);

    setPartnerPermissions(permissions);

    setUserEmployee(employee);
  }

  return { selectEmployee };
}
